<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['drynessOccurrence'])">
      <multi-answer-question
        title="label.drynessOccurrence"
        hint="label.selectAllThatApply"
        :value="drynessOccurrence"
        :options="$options.drynessOccurrenceOptions"
        dysfunction="skin-dryness"
        @input="onFieldChange('drynessOccurrence', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { DRYNESS_OCCURRENCE_OPTIONS } from '@/modules/questionnaire/constants/steps/skinDryness';

export default {
  name: 'DehydrationTemplate',
  components: { QuestionWithErrorWrap, MultiAnswerQuestion },
  drynessOccurrenceOptions: DRYNESS_OCCURRENCE_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    drynessOccurrence: {
      type: Array,
      default: () => []
    }
  }
};
</script>
